import { Injectable, inject } from '@angular/core';
import { Environment } from '@environments/environment-model';
import { SupportedCountryCode } from '@models/backend/common';
import { CountryService } from './country.service';
import { EnvironmentService } from './environment.service';

type FeatureToggle = {
    featureName: string;
    environments: Environment[];
    countries: SupportedCountryCode[];
};

export enum Feature {
    GeneralInquiry = 'general-inquiry',
}
@Injectable({
    providedIn: 'root',
})
export class FeatureToggleService {
    private environmentService = inject(EnvironmentService);
    private countryService = inject(CountryService);

    private featureToggles: FeatureToggle[] = [
        {
            featureName: Feature.GeneralInquiry,
            countries: ['CA'],
            environments: ['local', 'development', 'staging', 'production'], // 'production' is not included
        },
    ];

    isActive(featureName: string): boolean {
        const environment = this.environmentService.getEnvinronment().name;
        const country = this.countryService.getCurrentCountry();

        const isActive = this.featureToggles.find(
            (f) =>
                f.featureName === featureName && f.environments.includes(environment) && f.countries.includes(country),
        );

        if (isActive) {
            return true;
        }

        return false;
    }
}
