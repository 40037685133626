@if (!isLoading) {
    <div class="general-inquiry-view">
        @if (!isForbidden) {
            @if (hasInquiries) {
                <section data-test="filter" class="filter" [formGroup]="searchAndFilter">
                    <mat-form-field>
                        <mat-icon matSuffix>search</mat-icon>
                        <input
                            matInput
                            type="text"
                            formControlName="searchForm"
                            autocomplete="off"
                            highlightActiveFormControl
                        />
                        <mat-label>{{ 'SHARED_COMPONENT.SEARCH_FOR_A_NAME' | translate }}</mat-label>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ 'SHARED_COMPONENT.STATUS' | translate }}</mat-label>
                        <mat-select formControlName="status" highlightActiveFormControl>
                            <mat-option *ngFor="let status of statuses" [value]="status.value">{{
                                status.label | translate
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ 'SHARED_COMPONENT.REGION' | translate }}</mat-label>
                        <mat-select formControlName="region" highlightActiveFormControl>
                            <mat-option [value]="null">{{ 'SHARED_COMPONENT.ALL' | translate }}</mat-option>
                            <mat-option *ngFor="let region of regions; trackBy: byIndex" [value]="region">{{
                                region
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </section>

                <section data-test="general-inquiry-item">
                    @for (generalInquiry of generalInquiries; let last = $last; track $index) {
                        <general-inquiry-item
                            [generalInquiry]="generalInquiry"
                            (emailSelected)="email($event)"
                            (editClicked)="edit($event)"
                            (deleteSelected)="delete($event)"
                            (statusChanged)="setStatus($event)"
                            (createSelected)="convertGeneralInquiryToProspect($event)"
                            infinityScrolling
                            (elementVisible)="onScroll(last)"
                            [isTargetElement]="last"
                        ></general-inquiry-item>
                    }
                </section>

                @if (!generalInquiries.length) {
                    <div class="empty">
                        <warning-box
                            text="{{ 'GENERAL_INQUIRIES_VIEW.NO_GENERAL_INQUIRIES_FOUND' | translate }}"
                        ></warning-box>
                    </div>
                }
            } @else {
                @if (!generalInquiries.length) {
                    <div class="empty">
                        <warning-box
                            text="{{ 'GENERAL_INQUIRIES_VIEW.NO_GENERAL_INQUIRIES_AVAILABLE' | translate }}"
                        ></warning-box>
                    </div>
                }
            }
        }

        @if (isForbidden) {
            <div class="empty forbidden">
                <warning-box [type]="'warning'" [text]="notAllowedErrorMessage()"></warning-box>
            </div>
        }
    </div>
}

<loading-indicator></loading-indicator>
